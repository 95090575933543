<template>
  <v-container>
    <!-- Poznámky pro soutěž -->
    <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>
    <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
    <v-card>
      <v-toolbar flat dense>
        <v-tabs v-model="main" class="flex-grow-1" bg-color="primary" slider-color="#F1A829" center-active show-arrows>
          <!-- Zachování původní záložky eventu -->
          <v-tab value="event">{{ event.event_name }}</v-tab>
          <v-divider :thickness="10" class="border-opacity-100" vertical color="#FFFFFF"></v-divider>
          <!-- Dynamické generování záložek pro etapy získané z API -->
          <v-tab v-for="(race, etap) in races" :key="race.id" :value="`race-${race.id}`">
            E{{ etap + 1 }}: {{ race.race_name }}
          </v-tab>
        </v-tabs>
        <!-- Zachování tlačítka nastavení -->
        <v-btn v-if="isOrganizer" :to="'/soutez/' + event.id + '/nastaveni'" style="height:100%; margin-inline-end: 0px"><v-icon size="28">mdi-cog</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="main">
          <v-window-item value="event">
            <v-alert type="info" v-if="race0.message_info">{{ race0.message_info }}</v-alert>
            <v-alert type="warning" v-if="race0.message_warning">{{ race0.message_warning }}</v-alert>

            <v-card v-if="event && event.event_organizer">
              <v-row justify="space-between">
              <!-- První sloupec -->
                <v-col cols="12" md="6">
                  <v-card-title>{{ event.event_name }}</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <b>Datum:</b> {{ $formatDate(event.event_date_start) }}<span v-if="event.event_date_end"> - {{ $formatDate(event.event_date_end) }}</span>
                      </v-list-item>
                      <v-list-item>
                        <b>Pořadatel:</b> {{ event.club_name }} 
                        <v-tooltip location="top center" origin="auto" text="Soutěž je soukromá jen pro tvůj oddíl">
                          <template v-slot:activator="{ props }">
                            <v-chip v-bind="props" v-if="event.visibility == 'CLUB'" prepend-icon="mdi-lock-open" color="orange-darken-1" variant="flat" size="x-small">Pouze oddíl</v-chip>
                          </template>
                        </v-tooltip>
                      </v-list-item>
                      <v-list-item>
                        <b>Typ soutěže:</b> {{ event.event_type_display }}
                      </v-list-item>
                      <v-list-item v-if="race0.entry_deadline_1">
                        <b>Termín přihlášek:</b> <span v-if="!race0.entry_deadline_2">{{ $formatDateTime(race0.entry_deadline_1) }}</span>
                        <div v-if="race0.entry_deadline_2">1. termín: {{ $formatDateTime(race0.entry_deadline_1) }}</div> 
                        <div v-if="race0.entry_deadline_2"> 2. termín: {{ $formatDateTime(race0.entry_deadline_2) }}</div>
                      </v-list-item>
                      <v-list-item v-if="race0.race_start">
                        <b>Start 00:</b> {{ $formatTime(race0.race_start) }}
                      </v-list-item>
                      <v-list-item v-if="event.event_location">
                        <div><b>Souřadnice shromaždiště:</b> <a :href="`https://mapy.cz/turisticka?q=${event.event_location}`" target="_blank">{{ event.event_location }}</a></div>
                      </v-list-item>
                      <v-list-item v-if="event.contact">
                        <div><b>Kontakt na organizátora:</b> {{ event.contact }}</div>
                      </v-list-item>
                      <v-list-item v-if="event.event_note">
                        <b>Poznámka:</b> <span style="white-space: pre-wrap;">{{ event.event_note }}</span>
                        
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-col>

                  <!-- Druhý sloupec -->

                  <v-col cols="12" md="3">

                    <v-col>
                    <div v-if="event.entry_is_open && !isDeadlinePassed">
                      <!-- Zkontroluj, zda je uživatel již přihlášen -->
                      <div v-if="userHasEntry && authStore.isLoggedIn" class="text-center">
                      <v-btn 
                        text color="info"
                        class="pa-3 my-1 text-center"
                        width="100%"
                        height="100%"
                        style="line-height: 25px;"
                        :to="'/soutez/' + event.id + '/prihlaska/'">
                        Upravit přihlášku
                      </v-btn>
                      <p>do {{ currentDeadline }} <br> <span :class="{'text-red': deadlineTerm === '2. termín'}">{{ deadlineTerm }}</span></p>
                    </div>
                <div v-else class="text-center">
                      <v-btn
                        text color="success"
                        class="pa-3 my-1 text-center"
                        width="100%"
                        height="100%"
                        style="line-height: 25px;"
                        :to="'/soutez/' + event.id + '/prihlaska/'">
                        Přihlásit se
                      </v-btn>
                      <p>do {{ currentDeadline }} <br> <span :class="{'text-red': deadlineTerm === '2. termín'}">{{ deadlineTerm }}</span></p>
                    </div>
                  </div>

                    <div v-if="event.entry_is_open && isDeadlinePassed">
                      <v-alert type="info">{{ currentDeadline }}</v-alert>
                      <v-btn
                        v-if="userHasEntry && authStore.isLoggedIn"
                        text color="primary"
                        class="pa-3 my-1 text-center"
                        width="100%"
                        height="100%"
                        style="line-height: 25px;"
                        :to="'/soutez/' + event.id + '/prihlaska/'">
                        Moje přihláška
                      </v-btn>
                    </div>
                    <div v-if="!event.entry_is_open">
                      <v-alert type="info">Přihlašování ještě nezačalo</v-alert>
                    </div>
                  </v-col>

                </v-col>

                <!-- Třetí sloupec -->
                <v-col cols="12" md="3" v-if="files && files.length > 0">
                  <v-card v-if="filesWithLocation.length > 0">
                  <v-card-title>Soubory: </v-card-title>
                  <v-card-text>
                    <v-list v-for="file in filesWithLocation" :key="file.id">           
                        <v-list-item-title>
                          <a :href="`${BaseUrl}${file.location}`" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                        </v-list-item-title>
                    </v-list>
                  </v-card-text>
                  </v-card>

                  <v-card class="mt-2" v-if="filesWithLinks.length > 0">
                    <v-card-title>Odkazy: </v-card-title>
                    <v-card-text>
                      <v-list v-for="file in filesWithLinks" :key="file.id">           
                        <v-list-item-title>
                          <a :href="file.url" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                        </v-list-item-title>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>

              
              </v-row>
            </v-card>
          </v-window-item>

          <!-- Dynamické generování obsahu pro etapy -->
          <v-window-item v-for="(race, etap) in races" :key="race.race" :value="`race-${race.id}`">
            <v-alert type="info" v-if="race.message_info">{{ race.message_info }}</v-alert>
            <v-alert type="warning" v-if="race.message_warning">{{ race.message_warning }}</v-alert>

              <v-row>
                <v-col cols="12" md="9" class="">
                  <v-card-title>Etapa {{ etap + 1 }}: {{ race.race_name }}</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <b>ID:</b> {{ race.id }}
                      </v-list-item>
                      <v-list-item>
                        <b>Datum:</b> {{ $formatDate(race.race_date) }}
                      </v-list-item>
                      <v-list-item>
                        <b>Úroveň soutěže:</b> {{ race.race_level_display }}
                      </v-list-item>
                      <v-list-item v-if="race.race_type_display">
                        <b>Disciplína:</b> {{ race.race_type_display }}
                      </v-list-item>
                      <v-list-item v-if="race.race_band_display">
                        <b>Pásmo:</b> {{ race.race_band_display }}
                      </v-list-item>
                      <v-list-item v-if="race.race_start">
                        <b>Start 00:</b> {{ $formatTime(race.race_start) }}
                      </v-list-item>
                      <v-list-item v-if="race.race_time_limit">
                        <b>Limit:</b> {{ formatTimeInMinutes(race.race_time_limit) }} minut
                      </v-list-item>
                      <v-list-item v-if="race.race_note">
                        <b>Poznámka:</b> <span style="white-space: pre-wrap;">{{ race.race_note }}</span>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-col>

                <!-- Druhý sloupec -->
                <v-col cols="12" md="3" v-if="files && files.length > 0">
                  <v-card v-if="filesWithLocation.length > 0">
                  <v-card-title>Soubory: </v-card-title>
                  <v-card-text>
                    <v-list v-for="file in filesWithLocation" :key="file.id">           
                        <v-list-item-title>
                          <a :href="`${BaseUrl}${file.location}`" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                        </v-list-item-title>
                    </v-list>
                  </v-card-text>
                  </v-card>

                  <v-card class="mt-2" v-if="filesWithLinks.length > 0">
                    <v-card-title>Odkazy: </v-card-title>
                    <v-card-text>
                      <v-list v-for="file in filesWithLinks" :key="file.id">           
                        <v-list-item-title>
                          <a :href="file.url" target="_blank" class="font-weight-bold">{{ file.name }}</a>
                        </v-list-item-title>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
           
              </v-row>
          </v-window-item>

        </v-window>
      </v-card-text>

      <!-- Další záložky, které jsou pro celý event -->

      <v-tabs v-model="second" bg-color="primary" fixed-tabs slider-color="#F1A829">
        
        <v-tab value="automodule" v-if="isRace0 && authStore.isLoggedIn">Automodul</v-tab>
        <v-tab value="entries" v-if="isRace0 && entries.length > 0">Přihlášení závodníci</v-tab>
        <v-tab value="startlist" v-if="!isRace0 && startlist.length > 0">Startovka</v-tab>
        <!-- <v-tab value="results">Výsledky</v-tab>  -->


      </v-tabs>
      <v-card-text>
        <v-window v-model="second">
      
          <v-window-item value="automodule" v-if="isRace0 & authStore.isLoggedIn">
           
            <!-- Zobrazení úspěšné nebo chybové zprávy -->
            <v-alert type="success" v-if="successMessageAutomodule">{{ successMessageAutomodule }}</v-alert>
            <v-alert type="error" v-if="errorMessageAutomodule">{{ errorMessageAutomodule }}</v-alert>

            <v-btn color="primary" @click="fetchAutomodules(true)">Zobrazit všechnu dopravu</v-btn>
            <v-progress-circular v-if="allAutomoduleLoading" indeterminate color="primary"></v-progress-circular>




            <!-- Ikona pro vytvoření nového Automodule -->
            <v-icon @click="openCreateAutomoduleDialog" color="green" class="mt-2" size="45" style="float:right" title="Přidej auto">mdi-plus</v-icon>


            <v-card-text>
              <b>Nemají dopravu: </b>
              <span v-if="filteredEntries.length > 0">
                <span v-for="(entry, index) in filteredEntries" :key="entry.id">
                  {{ entry.last_name }} {{ entry.first_name }}<span v-if="entry.wayNotice"> ({{ entry.wayNotice }})</span><span v-if="index < filteredEntries.length - 1">, </span>
                </span>
              </span>
              <span v-else style="color:green">
                všichni mají dopravu!
              </span>
            </v-card-text>


            <!-- Dialog pro vytvoření Automodule -->
            <v-dialog v-model="dialogCreateAutomodule" persistent max-width="400px">
              <v-card>
                <v-card-title class="text-h5">Vytvořit nové auto</v-card-title>
                <v-alert type="error" v-if="errorMessage">{{ errorMessage }}</v-alert>
                <v-card-text>
                  <v-form @submit.prevent="submitAutomodule">
                    <v-text-field
                      v-model="title"
                      label="Místo a čas odjezdu"
                      required
                    ></v-text-field>
                    <v-text-field
                      v-model="maxEntries"
                      label="Počet míst"
                      type="number"
                      required
                      :rules="[v => v > 0 && v <= 50 || 'Počet míst musí být mezi 1 a 50']"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogCreateAutomodule = false">Zrušit</v-btn>
                  <v-btn color="green darken-1" :disabled="!isFormValid" text @click="submitAutomodule">Vytvořit</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-alert type="success" v-if="successMessage">{{ successMessage }}</v-alert>

    <!-- **********************************
     **************************************
     ********   Seznam Automodule    ******
     **************************************
     ************************************** -->

    <v-row>
      <v-col v-for="automodule in automodules" :key="automodule.id" cols="12" md="6">
        <v-card>
          <v-card-title>
            <v-row>
              <v-col>{{ automodule.club_shortcut }}</v-col>
              <v-spacer></v-spacer>
              <v-col style="flex-grow: 0;">
                <v-icon
                  v-if="!isEditing || automodule.editing"
                  color="blue"
                  @click="toggleEditAutomodule(automodule)"
                  v-show="canEditAutomodule(automodule)"
                >
                  {{ automodule.editing ? 'mdi-content-save' : 'mdi-pencil' }}
                </v-icon>
                <v-icon
                v-if="automodule.editing"
                  color="grey"
                  @click="automodule.editing = false"
                  title="zpět"
                >
                  mdi-arrow-u-left-top
                </v-icon>
                <v-icon
                  v-if="!isEditing || automodule.editing"
                  color="red"
                  @click="automodule.dialogDeleteAutomodule = true"
                  v-show="canEditAutomodule(automodule)"
                >
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title class="py-0" style="white-space: normal;">
            <span v-if="!automodule.editing">{{ automodule.title }}</span>
            <v-text-field
              v-if="automodule.editing"
              v-model="automodule.title"
              label="Místo a čas odjezdu"
              density="comfortable"
              :rules="[value => !!value || 'Povinné pole']"
            ></v-text-field>
          </v-card-title>
          <v-card-subtitle style="white-space: normal;">
            <span v-if="!automodule.editing">{{ automodule.notice }}</span>
          </v-card-subtitle>
          <v-row v-if="automodule.editing">
            <v-col cols="12" md="9" class="py-0">
              <v-text-field
                v-model="automodule.notice"
                label="Poznámka"
                density="comfortable"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0">
              <v-text-field
                v-model="automodule.max_entries"
                label="Počet míst"
                type="number"
                :rules="[v => v > 0 && v <= 50 || 'Počet míst musí být mezi 1 a 50']"
                density="comfortable"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="automodule.editing">
            <v-col cols="12" md="4" class="pt-0">
              <v-text-field
                v-model="automodule.date_there"
                label="Datum tam"
                type="date"
                density="comfortable"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5" class="pt-0">
              <v-text-field
                v-model="automodule.place_there"
                label="kam jedu"
                density="comfortable"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
              <v-text-field
                v-model="automodule.km_there"
                type="number"
                label="Počet km"
                density="comfortable"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="automodule.editing">
            <v-col cols="12" md="4">
              <v-text-field
                v-model="automodule.date_back"
                label="Datum zpět"
                type="date"
                density="comfortable"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                v-model="automodule.place_back"
                label="kam domů"
                density="comfortable"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="automodule.km_back"
                type="number"
                label="Počet km"
                density="comfortable"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <v-list dense>
            <v-list-item v-for="(entry, index) in automodule.entries" :key="entry.id">
              <v-list-item-content>
                <v-row>
                  <v-col style="display: flex; align-items: center;">
                    <v-icon v-if="index === 0" class="mr-2">mdi-steering</v-icon>
                    <v-tooltip v-else-if="!entry.editing" location="top center" origin="auto" :text="getTooltipText(entry.way)">
                      <template v-slot:activator="{ props }">
                        <v-icon v-bind="props" class="mr-2">{{ getIconClass(entry.way) }}</v-icon>
                      </template>
                    </v-tooltip>
                    <v-icon v-else @click="toggleWay(entry)" class="mr-2">{{ getIconClass(entry.way) }}</v-icon>
                    <span :class="{'grey--text': !entry.name}" v-if="!entry.editing" :style="{ minWidth: maxEntryNameWidth + 'px' }">
                      <b>{{ entry.name || 'volné místo' }} 
                        <v-tooltip location="top center" origin="auto" text="Není člen oddílu, nebo nemá spárovanou přihlášku. Nebude se započítávat do financí. Zkásni si ho sám.">
                          <template v-slot:activator="{ props }">
                            <v-icon v-bind="props" v-if="entry.name && entry.club_shortcut != automodule.club_shortcut"  color="red darken-1">mdi-map-marker-alert</v-icon>
                          </template>
                        </v-tooltip>
                      </b>
                    </span>
                    <span v-if="!entry.editing">{{ entry.notice || '' }}</span>
                    <v-text-field
                      v-if="entry.editing"
                      v-model="entry.name"
                      label="Příjmení a Jméno"
                      density="comfortable"
                      hide-details
                      clearable
                      @input="showSuggestions(entry)"
                    ></v-text-field>
                    <v-list v-if="entry.showSuggestions && suggestions.length > 0">
                      <v-list-item
                        v-for="(suggestion, index) in suggestions"
                        :key="index"
                        @click="selectSuggestion(entry, suggestion)"
                      >
                        {{ suggestion.last_name }} {{ suggestion.first_name }}
                      </v-list-item>
                    </v-list>
                    <v-text-field
                      v-if="entry.editing"
                      v-model="entry.notice"
                      label="Poznámka"
                      density="comfortable"
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col style="flex-grow: 0;">
                  <v-icon
                    color="blue"
                    @click="toggleEditEntry(entry, automodule)"
                    v-show="entry.editing || canEditEntry(entry, automodule) && !isEditingEntryOtherThan(entry)"
                  >
                    {{ entry.editing ? 'mdi-content-save' : 'mdi-pencil' }}
                  </v-icon>
                  <v-icon
                    v-if="entry.editing"
                    color="grey"
                    @click="entry.editing = false"
                    title="zpět"
                  >
                    mdi-arrow-u-left-top
                  </v-icon>
                </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>


        <!-- Dialog pro potvrzení smazání Automodule -->
        <v-dialog v-model="automodule.dialogDeleteAutomodule" persistent max-width="600px" class="dialog-over-dialog">
          <v-card>
            <v-card-title class="text-h5">Potvrzení smazání</v-card-title>
            <v-card-text>Opravdu chcete smazat automodule: <b>{{ automodule.title }}</b>?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="automodule.dialogDeleteAutomodule = false">Zrušit</v-btn>
              <v-btn color="red darken-1" text @click="confirmDeleteAutomodule(automodule)">Smazat</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>









          </v-window-item>


              <!-- **********************************
              **************************************
              *******   Seznam přihlášených   ******
              **************************************
              ************************************** -->

          <v-col cols="12" md="8" class="mx-auto" v-if="isLoadingEntries">
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-col>

          <v-window-item value="entries" v-if="entries.length > 0 && isRace0">
              <v-row class="justify-space-between"> 
                <v-col cols="12" md="2"> <!-- v-if="isClubManager" dočasně oddělán -->
                  <v-menu offset-y transition="slide-y-transition">
                    <template v-slot:activator="{ props }">
                      <v-btn color="green" text v-bind="props">
                        Export <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item :href="`${BaseUrl}/export/?type=xlsx&event_id=${event.id}`">
                        <v-list-item-title>Soutěž xlsx</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3">
                  <v-btn color="primary" @click="toggleViewMode">
                  {{ viewMode === 'category' ? 'Seznam podle oddílů' : 'Seznam podle kategorií' }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="3" v-if="isClubManager">
                  <v-tooltip location="top center" origin="auto" text="Zkopíruje emaily přihlášených z tvého oddílu">
                    <template v-slot:activator="{ props }">
                      <v-btn v-bind="props" color="primary" @click="copyEmails">Zkopírovat emaily</v-btn>
                    </template>
                  </v-tooltip>  
                </v-col>
              </v-row>



            <!-- Menu pro zkopírování emailů členů z oddílu -->
            <!-- <v-menu v-if="isClubManager" offset-y transition="slide-y-transition">
              <template v-slot:activator="{ props }">
                <v-btn color="primary" text v-bind="props">
                  Zkopírovat emaily <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="copyEmails">
                  <v-list-item-title>Přihlášených</v-list-item-title>
                </v-list-item>
                <v-list-item :href="`${BaseUrl}/export/?type=csv&name=entry_matula&event_id=${event.id}`">
                  <v-list-item-title>Nepřihlášených</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->

            <v-snackbar color="success" v-model="showSnackbar" timeout="2000">
              Emaily členů oddílu byly zkopírovány
            </v-snackbar>
            
            <v-row v-if="viewMode === 'category'">

              <!-- Navigační lišta kategorií -->
              <v-row class="w-100" justify="center">
                <v-col cols="12" class="ma-1 text-center">
                  <v-btn
                    v-for="(categoryName, index) in Object.keys(sortedEntriesByCategory)"
                    :key="index"
                    @click="scrollToCategory(categoryName)"
                    text
                    :color="getCategoryButtonColor(categoryName)"
                    class="ma-1"
                  >
                    {{ categoryName }}
                  </v-btn>
                </v-col>
              </v-row>

            <v-col col="12" md="8" v-for="(categoryEntries, categoryName) in sortedEntriesByCategory" :key="categoryName" :id="`category-${categoryName.replace(/\s+/g, '-')}`" class="mx-auto">

              <v-card-title>{{ categoryName }} ({{ categoryEntries.length }})</v-card-title>
                <v-data-table
                  :headers="categoryEntriesHeaders"
                  :items="categoryEntries"
                  :items-per-page="-1"
                  class="elevation-1"
                  @touchstart.stop
                  @touchmove.stop
                  :sort-by="EntrySortByName"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td style="white-space: nowrap;">
                        <router-link v-if="item.account_id" :to="'/ucet/' + item.account_id">
                          {{ item.last_name }} {{ item.first_name }}
                        </router-link>
                        <template v-else>
                          {{ item.last_name }} {{ item.first_name }} <!--Pokud uživatel nemá v robisu účet, neukáže se na něj odkaz-->
                        </template>
                      </td>
                      <td>{{ item.competitor_index }}</td>
                      <td>{{ item.si_number }}</td>

                      <td v-for="race in races" :key="race.id">
                        {{ item.raceCategories && item.raceCategories[`race_${race.id}`] ? item.raceCategories[`race_${race.id}`] : '---' }}
                      </td>
                      
                    </tr>
                  </template>
                  <template v-slot:bottom></template>
                </v-data-table>
              </v-col>
              </v-row>
              <v-row v-else>

                <!-- Navigační lišta oddílů -->
                  <v-row class="w-100" justify="center">
                    <v-col cols="12" class="ma-1 text-center">
                      <v-btn
                        v-for="(clubName, index) in Object.keys(sortedEntriesByClub)"
                        :key="index"
                        @click="scrollToClub(clubName)"
                        text
                        class="ma-1"
                      >
                        {{ clubName }}
                      </v-btn>
                    </v-col>
                  </v-row>

                <v-col col="12" md="8" v-for="(clubEntries, clubName) in sortedEntriesByClub" :key="clubName" :id="`club-${clubName.replace(/\s+/g, '-')}`" class="mx-auto">
                  <v-card-title>{{ clubName }} ({{ clubEntries.length }})</v-card-title>
                  <v-data-table
                    :headers="ClubEntriesHeaders"
                    :items="clubEntries"
                    :items-per-page="-1"
                    class="elevation-1"
                    @touchstart.stop
                    @touchmove.stop
                    :sort-by="EntrySortByCategory"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td style="white-space: nowrap;">
                          <router-link v-if="item.account_id" :to="'/ucet/' + item.account_id">
                            {{ item.last_name }} {{ item.first_name }}
                          </router-link>
                          <template v-else>
                            {{ item.last_name }} {{ item.first_name }} <!--Pokud uživatel nemá v robisu účet, neukáže se na něj odkaz-->
                          </template>
                        </td>
                        <td>{{ item.category_name }}</td>
                        <td>{{ item.competitor_index }}</td>
                        <td>{{ item.si_number }}</td>

                        <td v-for="(race) in races" :key="race.id">
                          {{ item.raceCategories && item.raceCategories[`race_${race.id}`] ? item.raceCategories[`race_${race.id}`] : '---' }}
                        </td>

                      </tr>
                    </template>
                    <template v-slot:bottom></template>
                  </v-data-table>
                </v-col>
              </v-row>
          </v-window-item>


              <!-- **********************************
              **************************************
              ***********     Startovky    *********
              **************************************
              ************************************** -->


          <v-col cols="12" md="8" class="mx-auto" v-if="isLoadingStartlist && !isRace0 && !startlist.length == []">
            <v-skeleton-loader type="article"></v-skeleton-loader>
          </v-col>


          <v-window-item value="startlist" v-if="!isLoadingStartlist && startlist.length > 0 && !isRace0">
            <v-col cols="12" md="3">
              <v-btn color="primary" @click="toggleStartlistViewMode">
                {{ startlistViewMode === 'category' ? 'Seznam podle času' : 'Seznam podle kategorií' }}
              </v-btn>
            </v-col>
            
            <!-- Zobrazení podle katogorií -->
            <v-row v-if="startlistViewMode === 'category'">
              <v-col col="12" md="8" v-for="(categoryEntries, categoryName) in sortedStartlistByCategory" :key="categoryName" class="mx-auto">
                <v-card-title>{{ categoryName }} ({{ categoryEntries.length }})</v-card-title>
                  <v-data-table
                    :headers="GetStartlistHeaders"
                    :items="categoryEntries"
                    :items-per-page="-1"
                    class="elevation-1"
                    @touchstart.stop
                    @touchmove.stop
                    :sort-by="sortBy"
                  >
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ $formatTimeSec(item.competitor_start_time) }}</td>
                        <td>{{ item.full_name }}</td>
                        <td>{{ item.start_number }}</td>
                        <td>{{ item.si_number }}</td>
                        <td>{{ item.club_shortcut }}</td>
                      </tr>
                    </template>
                    <template v-slot:bottom></template>
                  </v-data-table>
              </v-col>
            </v-row>

            <!-- Zobrazení podle startovních časů -->
            <v-row v-else>
              <!-- Popisný řádek nad sloupci (zobrazen pouze jednou nahoře) -->
              <v-col col="12" md="8" class="mx-auto py-1">
                <div class="startlist-header-row">
                  <div class="startlist-header-cell">Příjmení a jméno</div>
                  <div class="startlist-header-cell">St. č.</div>
                  <div class="startlist-header-cell">Kategorie</div>
                  <div class="startlist-header-cell">Číslo čipu</div>
                  <div class="startlist-header-cell">Oddíl</div>
                </div>
              </v-col>

              <!-- Výčet startlistu -->
              <v-col col="12" md="8" v-for="(timeEntries, startTime) in sortedStartlistByTime" :key="startTime" style="border-bottom: 1px solid #000;" class="mx-auto py-1">
                <v-card-title>{{ $formatTimeSec(startTime) }}</v-card-title>
                  <div v-for="item in timeEntries" :key="item.index" class="startlist-item">
                    <div class="startlist-row">
                      <div class="startlist-cell">{{ item.full_name }}</div>
                      <div class="startlist-cell">{{ item.start_number }}</div>
                      <div class="startlist-cell">{{ item.category_name }}</div>
                      <div class="startlist-cell">{{ item.si_number }}</div>
                      <div class="startlist-cell">{{ item.club_shortcut }}</div>
                    </div>
                  </div>
              </v-col>
            </v-row>


            
 
          </v-window-item>
          <!-- 
          <v-window-item value="online_results">
            Online výsledky zde
          </v-window-item>

          <v-window-item value="results">
            Výsledky zde
          </v-window-item> -->

        </v-window>
      </v-card-text>


    </v-card>
    
  </v-container>
</template>




<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import apiClient from '/api.js';
import { useAuthStore } from '/auth.js';
import { useRoute } from 'vue-router';
import { useDateTimeFormat } from '../composables/useDateTimeFormat.js';


const authStore = useAuthStore();
const route = useRoute();
const { formatDateTime } = useDateTimeFormat();
const BaseUrl = ref(process.env.VUE_APP_API_BASE_URL.replace(/\/+$/, '').replace(/\/api\/?$/, '')); // Odstraní "api/" a nadbytečná lomítka
const event = ref({});
const races = ref([]);
const race0 = ref({});
const currentRaceId = ref(race0.value.id); // Zde uchováváme aktuálně vybrané ID závodu
const files = ref([]);
const entries = ref([]);
const main = ref('event');  // Přidáno pro inicializaci na první záložku main části
const second = ref('automodule');
const successMessage = ref('');
const errorMessage = ref('');
const userHasEntry = ref(false);
const entriesByCategory = ref({});
const categoryEntriesHeaders = ref([
  { title: 'Příjmení a jméno', key: 'last_name' },
  { title: 'Index', key: 'competitor_index', width: '80px' },
  { title: 'Číslo čipu', key: 'si_number', width: '120px' },
]);

const ClubEntriesHeaders = ref([
  { title: 'Příjmení a jméno', key: 'last_name' },
  { title: 'Kat.', key: 'category_name', width: '80px' }, 
  { title: 'Index', key: 'competitor_index', width: '80px' },
  { title: 'Číslo čipu', key: 'si_number', width: '120px' },
]);


// Přidání sloupců pro jednotlivé etapy (races) dynamicky
const updateHeadersWithRaces = (races) => {
  races.forEach((race, index) => {
    const newHeader = { title: `E${index + 1}`, key: `race_${race.id}`, width: '50px', sortable: false };
    categoryEntriesHeaders.value.push(newHeader);  // Přidání pro hlavní tabulku (podle kategorií)
    ClubEntriesHeaders.value.push(newHeader);  // Přidání pro klubovou tabulku
  });
};



const EntrySortByName = [{key: 'last_name', order: 'asc'}]; // Klíč pro řazení
const EntrySortByCategory = [{key: 'category_name', order: 'asc'}]; // Klíč pro řazení



// Oprávnění organizátora, závisí na tom, jestli je organizátor ve stejném klubu jako event nebo je editor
const isOrganizer = computed(() => {
  // Převod Proxy objektu na běžné pole pro jednoduchý přístup
  const rolesArray = Array.from(authStore.roles);
  const isRoleOrganizer = rolesArray.includes('organizer');
  const isSameClub = Number(authStore.userClub) === Number(event.value.club);
  // Podmínky pro editora
  const editorsArray = event.value.editors || [];
  const isUserEditor = editorsArray.includes(Number(authStore.userID));
  const isAuthor = Number(event.value.author) === Number(authStore.userID);

  // Oprávnění pro organizátora nebo když je editors prázdné
  console.log('Is Organizer:', isRoleOrganizer, 'Is Same Club:', isSameClub, 'Is User Editor:', isUserEditor);
  return isAuthor || isUserEditor || (editorsArray.length === 0 && isRoleOrganizer && isSameClub); // user je editor nebo pokud nejsou editoři, je organozátor
});

// Oprávnění správce oddílu
const isClubManager = useAuthStore().roles.includes('club_manager');


// Vytvořeí isRace0 pro zobrazování některých záložek pouze na stránce o eventu
const isRace0 = computed(() => {
  return currentRaceId.value === race0.value.id;
});

// Filtrování souborů pro zobrazení pouze odkazů
const filesWithLinks = computed(() => files.value.filter(file => file.is_link));
// Filtrování souborů pro zobrazení pouze souborů
const filesWithLocation = computed(() => files.value.filter(file => file.is_file));

// seřazení přihlášených závodníků podle kategorií a podle abecedy
const sortedEntriesByCategory = computed(() => {
  // Získání klíčů (názvů kategorií) a jejich seřazení
  const sortedCategoryNames = Object.keys(entriesByCategory.value).sort();
  // Vytvoření nového objektu, kde klíče jsou seřazené názvy kategorií
  const sortedEntries = {};
  sortedCategoryNames.forEach(categoryName => {
    sortedEntries[categoryName] = entriesByCategory.value[categoryName];
  });
  return sortedEntries;
});

// logika pro zobrazení správného deadline data
const currentDeadline = computed(() => {
  const now = new Date();
  const deadline1 = new Date(race0.value.entry_deadline_1);
  const deadline2 = new Date(race0.value.entry_deadline_2);

  if (now > deadline1) {
    return now > deadline2 ? "Přihlašování skončilo" : formatDateTime(race0.value.entry_deadline_2);
  } else {
    return formatDateTime(race0.value.entry_deadline_1);
  }
});

const deadlineTerm = computed(() => {
  const now = new Date();
  const deadline1 = new Date(race0.value.entry_deadline_1);
  return now > deadline1 ? "2. termín" : "1. termín";
});

const isDeadlinePassed = computed(() => {
  const now = new Date();
  const deadline1 = new Date(race0.value.entry_deadline_1);
  const deadline2 = new Date(race0.value.entry_deadline_2);
  // Kontrola, zda aktuální čas je po druhém deadline
  // a také kontrola, že druhý deadline je po prvním deadline (což je logické a zároveň zajišťuje, že oba termíny jsou prošlé)
  return now > deadline2 && now > deadline1;
});

 // Sledujte změnu v aktuálně vybrané záložce a mění Race ID
watch(main, (newValue) => {
  if (newValue === 'event' && race0.value && race0.value.id) {
    currentRaceId.value = race0.value.id;
  }
});

onMounted(async () => {
  await loadEventAndRacesData(); // Načtení etap spojených s eventem
  loadSuccessMessage(); // načtení success hlášky
  if (!currentRaceId.value && race0.value.id) {
    currentRaceId.value = race0.value.id; // Nastavení currentRaceId při prvním načtení stránky
  }
  // fetchStartlist(currentRaceId.value); // načtení startovek
  updateHeadersWithRaces(races.value); // Aktualizace hlaviček tabulky
  fetchAutomodules(); // načtení automodulu

  updateSecondTab(); // načtení aktivní záložky podle dostupných dat

});


// nastavení podle čeho se má zobrazit aktivní záložka v soutěži 
const updateSecondTab = () => {
  console.log(`Entries: ${entries.value.length}, Startlist: ${startlist.value.length}, Results: ${results.value.length}`);

  if (results.value.length > 0) {
    second.value = 'results';
  } else if (startlist.value.length > 0) {
    second.value = 'startlist';
  } else if (entries.value.length > 0) {
    second.value = 'entries';
  } else {
    second.value = 'automodule';
  }
};


function loadSuccessMessage() {
  const message = localStorage.getItem('successMessage');
  if (message) {
    successMessage.value = message;
    localStorage.removeItem('successMessage'); // Odstranění hlášky, aby se nezobrazovala opakovaně
  }
}


async function loadEventAndRacesData() {
  const eventId = route.params.id; // Předpokládá se získání ID eventu z URL
  try {
    const response = await apiClient.get(`event/edit/?id=${eventId}`);
    event.value = response.data;
    // Seřadit závody podle ID
    const sortedRaces = response.data.races.sort((a, b) => a.id - b.id);
    // uloží hodoty pro první race0
    race0.value = sortedRaces.length > 0 ? sortedRaces[0] : null;
    // Odstranit první závod (s nejnižším ID)
    const racesWithoutFirst = sortedRaces.slice(1);
    // Uložit upravené závody do races v pořadí podle ryce_date a race_start
    races.value = racesWithoutFirst.sort((a, b) => {
      if (a.race_date < b.race_date) return -1;
      if (a.race_date > b.race_date) return 1;
      if (a.race_start < b.race_start) return -1;
      return a.race_start > b.race_start ? 1 : 0;
    });
    // Po vytvoření race0 se toto id přenese do fetchCategories atd., aby se kategorie race0 načetli při otevření nastavení, když je vybraný Event
    // Načtení souborů a přihlášek pro Race0
    if (race0.value && race0.value.id) {
      await fetchFiles(race0.value.id);
      await fetchEntries(race0.value.id);
    }
  } catch (error) {
    console.error("There was an error fetching the event and races data:", error);
  }
}

// načtení souborů
async function fetchFiles(raceId) {
  try {
    const response = await apiClient.get(`files/?race_id=${raceId}`);
    files.value = response.data;
  } catch (error) {
    console.error("Nepodařilo se načíst soubory:", error);
  }
}

const isLoadingEntries = ref(false);

async function fetchEntries() {
  const eventId = route.params.id;
  isLoadingEntries.value = true;
  try {
    const response = await apiClient.get(`entries/?event_id=${eventId}`);
    entries.value = response.data;
    // filtrování entries pro automodul
    filteredEntries.value = entries.value.filter(entry => entry.competitor_club === Number(authStore.userClub));
    processEntries(entries.value);
    checkUserEntry(); // Zavolejte novou metodu po načtení a zpracování přihlášek
    filterEntries(); // Filtrování entries po načtení přihlášek
  } catch (error) {
    console.error("Nepodařilo se načíst přihlášky:", error);
    entries.value = []; // Resetování pole přihlášek v případě chyby

  } finally {
    isLoadingEntries.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }
}

const viewMode = ref('category');

const toggleViewMode = () => {
  viewMode.value = viewMode.value === 'category' ? 'club' : 'category';
};

// Předpokládejme, že tato metoda je volána po načtení dat příhlášek z API
function processEntries(entriesData) {
  entriesByCategory.value = entriesData.reduce((acc, entry) => {
    const categoryName = entry.category_name || 'Nemá kategorii';
    acc[categoryName] = acc[categoryName] || [];
    
    // Pro každý entry, vytvořte samostatný objekt obsahující kategorie pro různé závody
    const raceCategories = {};
    entry.entry_race_data.forEach(race => {
      raceCategories[`race_${race.race_id}`] = race.category_name;
    });

    // Uložte do položky závodníka informace o kategoriích pro jednotlivé závody
    entry.raceCategories = raceCategories;

    acc[categoryName].push(entry);
    return acc;
  }, {});
}


const sortedEntriesByClub = computed(() => {
  const sortedClubEntries = {};

  entries.value.forEach(entry => {
    const clubName = entry.competitor_index.substring(0, 3).trim() || 'Nereg.'; // Pokud není oddíl, použije se "NEREG"; // Předpokládám, že zkratka klubu je prvních 3 znaky indexu
    if (!sortedClubEntries[clubName]) {
      sortedClubEntries[clubName] = [];
    }
    sortedClubEntries[clubName].push(entry);
  });

  // Seřazení klubů podle abecedy
  const sortedKeys = Object.keys(sortedClubEntries).sort();

  // Vytvoření nového objektu s řazenými kluby
  const sortedResult = {};
  sortedKeys.forEach(key => {
    sortedResult[key] = sortedClubEntries[key];
  });

  return sortedResult;
});


watch(entries, (newEntries) => {
  second.value = newEntries.length > 0 ? 'entries' : 'automodule';
}, { immediate: true });

function checkUserEntry() {
  const userEntry = entries.value.find(entry => entry.account_id === Number(authStore.userID ));
  
  userHasEntry.value = !!userEntry;
  if (userEntry) {
        // Uživatel má již přihlášku, aktualizujte UI odpovídajícím způsobem
        console.log("Uživatel je již přihlášen do závodu.");
        // Zde můžete například nastavit nějakou data property, která řídí, co se zobrazí uživateli
      } else {
        // Uživatel nemá přihlášku, zobrazte možnost přihlásit se
        console.log("Uživatel není přihlášen do závodu.");
      }
  }


// watcher na sledování v-model záložek pro event a races a následné načtení dat z api
watch(main, async (newTab) => {
  console.log(`Aktuální záložka: ${newTab}`);
  if (newTab === 'event') {
    currentRaceId.value = race0.value.id;
    // Vymazání startlistu a výsledků, protože pro event je nechceme zobrazovat
    startlist.value = [];
    isLoadingStartlist.value = false;
    results.value = [];

    await fetchFiles(race0.value.id);
    console.log('Načítám data pro hlavní záložku.');

  } else if (newTab.startsWith('race-')) {
    const raceId = Number(newTab.split('-')[1]); // Získání race ID ze záložky
    currentRaceId.value = raceId;

    await fetchFiles(raceId);
    await fetchStartlist(raceId);
  }

  updateSecondTab(); // načtení aktivní záložky podle dostupných dat ve všech případech

});




// Funkce pro konverzi času na minuty pro limit
function formatTimeInMinutes(time) {
  if (!time) return '';
  const parts = time.split(':');
  const hours = parseInt(parts[0], 10);
  const minutes = parseInt(parts[1], 10);
  return hours * 60 + minutes;
}


const showSnackbar = ref(false);

// Kopírování emailů přihlášených uživbatelů na soutěž pro správce oddílu
function copyEmails() {
  const filteredEntries = entries.value.filter(entry => String(entry.competitor_club) === String(authStore.userClub) && entry.email && !entry.email.includes('rob-is.cz'));
  const emails = filteredEntries.map(entry => entry.email);
  const emailString = emails.join(', ');
  navigator.clipboard.writeText(emailString).then(() => {
    showSnackbar.value = true;
  }).catch(err => {
    console.error('Failed to copy emails: ', err);
  });
}

// scrolování po kliknutí na příslušnou kategorii
function scrollToCategory(categoryName) {
  const categoryId = `category-${categoryName.replace(/\s+/g, '-')}`;
  const categoryElement = document.getElementById(categoryId);
  if (categoryElement) {
    const yOffset = -50; 
    const y = categoryElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}

// Namapování kategorií s atributy pro správné zobrazení barev tlačítek
const categoryAttributesMap = computed(() => {
  const map = {};
  event.value.races.forEach(race => {
    race.categories.forEach(category => {
      map[category.category_name] = {
        gender: category.category_gender,
        option: category.option
      };
    });
  });
  return map;
});


function getCategoryButtonColor(categoryName) {
  const attributes = categoryAttributesMap.value[categoryName];
  // Zkontroluje, zda je uživatel přihlášen v této kategorii
  const isUserInCategory = entries.value.some(
    entry => entry.category_name === categoryName && entry.account_id === Number(authStore.userID)
  );

  if (isUserInCategory) {
    return 'green'; // Kategorie, kde je uživatel přihlášen
  }
  if (!attributes) {
    return 'grey'; // Výchozí barva pro neznámé kategorie
  }
  if (attributes.option === 'OTHERS') {
    return 'grey'; // Pokud je raceType "OTHERS"
  }
  if (attributes.gender === 'F') {
    return 'red'; // Ženské kategorie
  } else if (attributes.gender === 'M') {
    return 'blue'; // Mužské kategorie
  }
  return 'grey'; // Výchozí barva
}


function scrollToClub(clubName) {
  const clubId = `club-${clubName.replace(/\s+/g, '-')}`;
  const clubElement = document.getElementById(clubId);
  if (clubElement) {
    const yOffset = -50; // Upravit odsazení podle potřeby
    const y = clubElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}



///////////////////////////////////////
//////                        /////////
//////      Automodul         /////////
//////                        /////////
///////////////////////////////////////



const successMessageAutomodule = ref('');
const errorMessageAutomodule = ref('');

const allAutomoduleLoading = ref(true);


const title = ref('');
const maxEntries = ref(5);
const automodules = ref([]);

const dialogCreateAutomodule = ref(false);

function openCreateAutomoduleDialog() {
  dialogCreateAutomodule.value = true;
}

const isFormValid = computed(() => {
  return title.value.trim() !== '' && maxEntries.value > 0 && maxEntries.value <= 50;
});


async function fetchAutomodules(all = false) {
  allAutomoduleLoading.value = true;  // Začneme s aktivovaným loadingem
  try {
    const eventId = route.params.id;
    const response = await apiClient.get(`automodule/?event_id=${eventId}&all=${all}`);
    automodules.value = response.data;
    automodules.value.forEach(automodule => {
      automodule.editing = false;
      automodule.entries.forEach(entry => {
        entry.editing = false;
        entry.showSuggestions = false; // Přidání showSuggestions do entry objektu
      });
    });
    filterEntries(); // Filtrování entries po načtení automodulu
  } catch (error) {
    console.error('Nastala chyba při získávání automodule:', error);

  } finally {
    allAutomoduleLoading.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }
}

// Výpočet a dosazení délky pro name u autoodule entry tak, aby se všechny řádky se jménem roztáhly stejně
const maxEntryNameWidth = ref(0);
watch(automodules, (newVal) => {
  maxEntryNameWidth.value = Math.max(...newVal.flatMap(automodule => automodule.entries.map(entry => entry.name ? entry.name.length * 8 : 80))) + 5;
}, { deep: true });


const canEditAutomodule = (automodule) => {
  const userIsClubManager = authStore.roles.includes('club_manager') && automodule.club_shortcut === authStore.index.substring(0, 3) || "";
  const userIsDriver = automodule.entries.some(entry => entry.driver && `${entry.name}` === `${authStore.last_name} ${authStore.firstName}`);
  const userIsCreator = automodule.created !== undefined && Number(authStore.userID) === Number(automodule.created);
  return userIsClubManager || userIsDriver || userIsCreator;
};

const canEditEntry = (entry, automodule) => {
  const userIsClubManager = authStore.roles.includes('club_manager') && (entry.club_shortcut === authStore.index.substring(0, 3) || automodule.club_shortcut === authStore.index.substring(0, 3));
  const userIsDriver = automodule.entries.some(entry => entry.driver && `${entry.name}` === `${authStore.last_name} ${authStore.firstName}`);
  const userIsEntry = entry.name === `${authStore.last_name} ${authStore.firstName}`;
  const userIsCreator = automodule.created !== undefined && Number(authStore.userID) === Number(automodule.created);
  const userIsAuthor = entry.author !== undefined && Number(authStore.userID) === Number(entry.author);
  return userIsClubManager || userIsDriver || userIsEntry || userIsCreator || userIsAuthor || !entry.name;
};


async function submitAutomodule() {
  try {
    const eventId = route.params.id;
    await apiClient.post(`automodule/?event_id=${eventId}`, {
      title: title.value,
      max_entries: maxEntries.value,
    });
    successMessage.value = 'Auto bylo úspěšně vytvořeno';
    errorMessageAutomodule.value = '';
    title.value = '';
    maxEntries.value = 5;
    dialogCreateAutomodule.value = false; // Zavřít dialog po úspěšném vytvoření
    fetchAutomodules();
  } catch (error) {
    errorMessageAutomodule.value = 'Nastala chyba při vytváření auta.';
    
  }
}

const isEditing = computed(() => {
  return automodules.value.some(automodule => automodule.editing) || automodules.value.some(automodule => automodule.entries.some(entry => entry.editing));
});


function toggleEditAutomodule(automodule) {
  automodules.value.forEach(am => {
    if (am !== automodule) {
      am.editing = false;
    }
  });
  automodule.editing = !automodule.editing;
  if (!automodule.editing) {
    updateAutomodule(automodule);
  }
}

// předvyplnění name přihlášeného uživatele z pinia store
const userFullName = computed(() => {
  return `${authStore.last_name} ${authStore.firstName}`;
});

// kliknutí na editaci uživatele
function toggleEditEntry(entry, automodule) {
  automodules.value.forEach(am => {
    am.entries.forEach(e => {
      if (e !== entry) {
        e.editing = false;
      }
    });
  });
  entry.editing = !entry.editing;
  if (entry.editing && !entry.name) {
    entry.name = userFullName.value; // Předvyplnění pole name
  }
  if (!entry.editing) {
    updateAutomodule(automodule);
  }
}



function toggleWay(entry) {
  if (entry.way === '1') {
    entry.way = '2';
  } else if (entry.way === '2') {
    entry.way = '3';
  } else {
    entry.way = '2';
  }
}

function getTooltipText(way) {
      if (way === '2') {
        return 'Tam';
      } else if (way === '3') {
        return 'Zpět';
      } else {
        return 'Tam i zpět';
      }
    }

function getIconClass(way) {
  if (way === '1') {
    return 'mdi-arrow-left-right';
  } else if (way === '2') {
    return 'mdi-arrow-right';
  } else {
    return 'mdi-arrow-left';
  }
}


async function updateAutomodule(automodule) {
  try {
// Připravit data pro odeslání, zahrnout entry_id a email z entry
  const entriesData = automodule.entries.map(entry => {
      const raceEntry = entries.value.find(e => `${e.last_name} ${e.first_name}` === entry.name);
      return {
        id: entry.id,
        name: entry.name || "",
        notice: entry.notice,
        email: raceEntry ? raceEntry.email : entry.email, // Vezme email z entry pro race nebo původní email
        driver: entry.driver,
        club_shortcut: entry.club_shortcut,
        timestamp: entry.timestamp,
        way: entry.way,
        entry: raceEntry ? raceEntry.id : null // entry se získá z příhlášky
      };
    });
    const automoduleData = {
      ...automodule, // zahrnout všechna pole z automodule
      entries: entriesData // Přidat entries data
    };

    await apiClient.put(`automodule/`, automoduleData);

    successMessage.value = 'Automodul byl úspěšně aktualizován!';
    errorMessageAutomodule.value = '';

  } catch (error) {
      if (error.response && error.response.status === 409) {
        errorMessageAutomodule.value = error.response.data.error || 'Data neuložena kvůli kolizi s databází';
      } else {
        errorMessageAutomodule.value = 'Nastala chyba při aktualizaci automodulu.';
      }
    } finally {
    fetchAutomodules(); // Načte aktuální data
  }
}



async function confirmDeleteAutomodule(automodule) {
  automodule.dialogDeleteAutomodule = false;
  try {
    await deleteAutomodule(automodule.id);
    successMessage.value = 'Automodule byl úspěšně smazán!';
    fetchAutomodules();
  } catch (error) {
    errorMessageAutomodule.value = 'Nastala chyba při mazání automodule.';
  }
}

async function deleteAutomodule(automoduleId) {
  await apiClient.delete(`automodule/?automodule_id=${automoduleId}`);
}

const filteredEntries = ref([]);

function filterEntries() {
  const clubEntries = entries.value.filter(entry => entry.competitor_club === Number(authStore.userClub));
  const usedNames = new Set();
  const entriesWithWay = {}; // Mapování entry_id na way

  automodules.value.forEach(automodule => {
    automodule.entries.forEach(entry => {
      if (entry.name) {
        if (entriesWithWay[entry.entry]) {
          entriesWithWay[entry.entry].push(entry.way);
        } else {
          entriesWithWay[entry.entry] = [entry.way];
        }
        if (entry.way === '1' || (entriesWithWay[entry.entry].includes('2') && entriesWithWay[entry.entry].includes('3'))) {
          usedNames.add(entry.name);
        }
      }
    });
  });

  filteredEntries.value = clubEntries.filter(entry => !usedNames.has(`${entry.last_name} ${entry.first_name}`));

  // Přidání poznámky pro závodníky s jednosměrnou cestou
  filteredEntries.value.forEach(entry => {
    const ways = entriesWithWay[entry.id];
    if (ways) {
      if (ways.includes('2') && !ways.includes('3')) {
        entry.wayNotice = "cesta zpět";
      } else if (ways.includes('3') && !ways.includes('2')) {
        entry.wayNotice = "cesta tam";
      } else {
        entry.wayNotice = ""; // Případ pro závodníky, kteří nemají žádnou jednosměrnou cestu
      }
    } else {
      entry.wayNotice = ""; // Případ pro závodníky, kteří nemají žádnou přihlášenou cestu
    }
  });
}



function isEditingEntryOtherThan(currentEntry) {
  return automodules.value.some(automodule =>
    automodule.entries.some(entry => entry.editing && entry !== currentEntry)
  );
}


// Našeptávač pro automodul

const suggestions = ref([]);

function showSuggestions(entry) {
  if (entry.name.length > 0) {
    suggestions.value = filteredEntries.value.filter(e =>
      e.last_name.toLowerCase().startsWith(entry.name.toLowerCase()) ||
      e.first_name.toLowerCase().startsWith(entry.name.toLowerCase())
    ).map(e => ({
      id: e.id,
      last_name: e.last_name,
      first_name: e.first_name,
      entry_id: e.id // Přiřazení entry_id do suggestion
    }));
    entry.showSuggestions = true;
  } else {
    entry.showSuggestions = false;
  }
}

function selectSuggestion(entry, suggestion) {
  entry.name = `${suggestion.last_name} ${suggestion.first_name}`;
  entry.entry_id = suggestion.entry_id; // Přiřazení entry_id do entry
  entry.showSuggestions = false;
}


///////////////////////////////////////
//////                        /////////
//////       Startovky        /////////
//////                        /////////
///////////////////////////////////////


const startlist = ref([]);

const startlistViewMode = ref('category');

const toggleStartlistViewMode = () => {
  startlistViewMode.value = startlistViewMode.value === 'category' ? 'time' : 'category';
};

// Seřazení startovní listiny podle kategorií
const sortedStartlistByCategory = computed(() => {
  const sortedCategoryEntries = {};
  startlist.value.forEach(item => {
    if (!sortedCategoryEntries[item.category_name]) {
      sortedCategoryEntries[item.category_name] = [];
    }
    sortedCategoryEntries[item.category_name].push(item);
  });
  return sortedCategoryEntries;
});


// Seřazení startovní listiny podle startovních časů
const sortedStartlistByTime = computed(() => {
  const sortedTimeEntries = {};
  const sortedList = [...startlist.value].sort((a, b) => {
    if (a.competitor_start_time < b.competitor_start_time) return -1;
    if (a.competitor_start_time > b.competitor_start_time) return 1;
    return 0;
  });

  sortedList.forEach(item => {
    const formattedTime = item.competitor_start_time;
    if (!sortedTimeEntries[formattedTime]) {
      sortedTimeEntries[formattedTime] = [];
    }
    sortedTimeEntries[formattedTime].push(item);
  });

  return sortedTimeEntries;
});


const GetStartlistHeaders = ref([

      { title: 'Čas startu', value: 'competitor_start_time', sortable: true },
      { title: 'Příjmení a jméno', value: 'full_name', sortable: true },
      { title: 'St. č.', value: 'start_number', sortable: false, align: 'center', width: '70px' },
      { title: 'Číslo čipu', value: 'si_number', sortable: true },
      { title: 'Oddíl', value: 'club_shortcut', sortable: true },
    ]);

const sortBy = [{key: 'competitor_start_time', order: 'asc'}];
const isLoadingStartlist = ref(true);

async function fetchStartlist(raceId) {
  isLoadingStartlist.value = true;
  try {
    const response = await apiClient.get(`/startlist/?race_id=${raceId}`);
    startlist.value = response.data
      .map(item => {
        return {
          ...item,
          full_name: `${item.last_name} ${item.first_name}`, // Spojení příjmení a jména do jednoho pole
          competitor_start_time: new Date(item.competitor_start_time),
        };
      })
      .sort((a, b) => { // Seřazení nejdříve podle category_name a poté podle start_time
        if (a.category_name < b.category_name) return -1;
        if (a.category_name > b.category_name) return 1;
        if (a.start_time < b.start_time) return -1;
        if (a.start_time > b.start_time) return 1;
        return 0;
      });
  } catch (error) {
    console.error('Chyba při načítání dat:', error);  
  } finally {
      isLoadingStartlist.value = false;  // Loading skončí po úspěšném načtení nebo při chybě
  }
}


///////////////////////////////////////
//////                        /////////
//////       Výsledky         /////////
//////                        /////////
///////////////////////////////////////

const results = ref([]);





</script>






<style scoped>


/** Zmenšení mezer mezi jednotlivými prvky item listu */
.v-list-item--density-default.v-list-item--one-line {
  min-height: 20px;
}

/* zmenšení margin, aby se do okna vešel rám oken */
.v-row {
  margin:-10px;
}

.text-red {
  color: red;
}

  :deep() table th + th { border-left:1px solid #dddddd; }
  :deep() table td + td { border-left:1px solid #dddddd; } 


  .grey--text {
  color: grey !important;
  opacity: 0.7;
}


.startlist-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.startlist-cell {
  flex: 1;
  text-align: left;
}

.startlist-cell:not(:last-child) {
  padding-right: 16px; /* Prostor mezi sloupci */
}


.startlist-header-row {
  display: flex;
  font-weight: bold;
}

.startlist-header-cell {
  flex: 1;
  padding: 8px;
}

</style>
