// src/version.js
export const versions = [
  { version: "0.4.0", date: "2024-11-24", notes: ["V nastavení soutěže byla do správy přihlášených přidána pro pořadatele možnost přihlášení závodníků.", "V nastavení soutěže nyní zakladatel může přidat editory soutěže a nastavit si tak, kdo může soutěž spravovat. Organizátorům v oddíle tímto znemožní editaci soutěže.", "V nastavení uživatelů bylo upraveno pole pro vyplnění správce mých přihlášek. Nyní je možnost vybrat konkrétního uživatele robisu ze seznamu.", "Bylo upraveno zobrazení dat v xlsx souboru.", "Oprava chyb a přizpůsobení jádra pro API v připravovaném JSON ARDF formátu.", "V záložce menu Další přibyla stránka API, kde najdete předběžný přístup k API ROBisu.", "Optimalizace načítání kalendáře a stránky soutěže."] },
  { version: "0.3.2", date: "2024-10-04", notes: ["Přidán export pro Livelox na spárování závodníků s kategoriemi.", "Oprava chyb."] },
  { version: "0.3.1", date: "2024-09-28", notes: ["Seznam přihlášených závodníků je dostupný pouze na stránce soutěže, z jednotlivých etap byl odstraněn.", "K elementům, u kterých nebylo úplně jasné, co dělají, byl přidaný popis, který je dostupný po najetí myši.", "Opraveno bylo chybné načítání stránky soutěže při swipování na mobilu, optimalizace načítání záložek.", "Drobné opravy a vylepšení."] },
  { version: "0.3.0", date: "2024-09-26", notes: ["Předělán systém cen kategorií tak, aby se šlo přihlásit do hlavní kategorie bez poplatku (resp. volitelně i s poplatkem).", "V kalendáři se nyní zobrazují i informace o etapách.", "V nastavení souteže je možnost zobrazení soutěže jen pro oddíl a možnost zobrazení zrušení závodu.", "Půjčení čipu se nyní započítává k celkové částce za přihlášku a tuto cenu lze editovat ve službách v nastavení soutěže."] },
  { version: "0.2.2", date: "2024-09-09", notes: ["Start 00 pro etapy, startovní čas a cílový čas závodníka nejsou nyní převáděny podle časového pásma.", "V sekci Členové je nyní k dispozici základní filtrace. Další přibude po integraci trenérů, rozhodčích a VT.", "V oddílové sekci bylo kompletně předěláno zobrazení podle preferencí vedoucích oddílů.", "Oprava chyb a menší úpravy textu."] },
  { version: "0.2.1", date: "2024-09-07", notes: ["Opravena chyba, kdy se na závod nemohlo přihlásit více lidí jednorázovou přihláškou.", "Přidáno zobrazení počtu přihlášených na závod a počet členů v oddíle."] },
  { version: "0.2.0", date: "2024-08-23", notes: ["Integrace startovek", "Stránka se všemy Exporty, které robis aktuálně nabízí."] },
  { version: "0.1.1", date: "2024-08-12", notes: ["Email při registraci již není povinný, viz nápověda.", "Opraveny drobné chyby."] },
  { version: "0.1.0", date: "2024-08-09", notes: ["Spuštění beta verze."] },
];

export const latestVersion = versions[0].version;
